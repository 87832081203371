
import { defineComponent, reactive, watch, onMounted } from 'vue';
import Icon from '@/components/icons/Icon.vue';
import HeaderButtons from '@/components/global/HeaderButtons.vue';
import CoverService from '@/services/CoverService';
import { useRoute } from 'vue-router';
import router from '@/router';
import CopyLink from "@/components/CopyLink.vue";

export default defineComponent({
  name: 'Header',
  components: {
    Icon,
    HeaderButtons,
    CopyLink
  },
  setup() {
    const refs = reactive({
      drawerOpen: false,
      connectMenuShown: false
    });

    watch(useRoute(), () => {
      if (refs.drawerOpen) {
        toggleDrawer();
      }
    });

    onMounted(() => {
      window.addEventListener('resize', onResize);
    });

    const onResize = () => {
      if (document.documentElement.clientWidth > 768 && refs.drawerOpen) {
        toggleDrawer();
      }
    };

    function toggleDrawer() {
      refs.drawerOpen = !refs.drawerOpen;
      if (refs.drawerOpen) {
        CoverService.addCover().then(() => (refs.drawerOpen = false));
      } else {
        CoverService.removeCover();
      }
    }

    function goHome() {
      router.push({ name: 'Home' });
    }

    function connect() {
      refs.connectMenuShown = !refs.connectMenuShown;
      if (refs.connectMenuShown) {
        CoverService.addCover('#45c463', focusListener).then(() => (refs.connectMenuShown = false));
      } else {
        CoverService.removeCover();
      }
    }

    const focusListener = (e: Event) => {
      const className = (e.target as HTMLElement).classList[0];
      if (!className || !className.startsWith('connect-menu')) {
        CoverService.removeCover();
      }
    }

    return {
      refs,
      toggleDrawer,
      goHome,
      connect
    };
  }
});
