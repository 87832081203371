
import { defineComponent, PropType, onBeforeMount, reactive, computed, onMounted } from 'vue';
import { ContentType, DevelopmentEntryContent } from '@/data/development';
import WebMockup from '@/components/development/WebMockup.vue';
import RequireImage from '@/util/RequireImage';
import Draggable from '@/components/Draggable.vue';
import { emitter } from '@/main';
import { Direction } from '@/types';

export default defineComponent({
  name: 'EntryCarousel',
  components: {
    WebMockup,
    Draggable
  },
  props: {
    content: {
      type: Object as PropType<DevelopmentEntryContent>,
      required: true
    }
  },
  setup(props) {
    const refs = reactive({
      index: 0,
      callbackPrefix: `${Math.random().toString().slice(2)}-carousel-draggable`
    });
    const computes = reactive({
      offset: computed(() => refs.index * 100 * 2)
    });
    const paths: string[] = [];

    onBeforeMount(() => {
      for (let i = 0; i < props.content.count; i++) {
        paths.push(RequireImage(`/development/${props.content.key}/_${i}`));
      }
    });

    onMounted(() => {
      emitter.on(`${refs.callbackPrefix}-end`, () => goToObject(refs.index - 1));
      emitter.on(`${refs.callbackPrefix}-start`, () => goToObject(refs.index + 1));
    });

    function goToObject(index: number) {
      refs.index = Math.max(Math.min(index, paths.length - 1), 0);
      emitter.emit(`${refs.callbackPrefix}-set-index`, refs.index);
    }

    return {
      refs,
      computes,
      paths,
      goToObject,
      ContentType,
      Direction
    };
  }
});
