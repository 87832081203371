
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContentHeader',
  props: {
    path: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    return {
      props
    };
  }
});
