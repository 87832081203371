
import { defineComponent, PropType, reactive } from 'vue';
import { DevelopmentEntryDetails } from '@/data/development';

export default defineComponent({
  name: 'EntryDetails',
  props: {
    details: {
      type: Object as PropType<DevelopmentEntryDetails>,
      required: true
    }
  },
  setup(props) {
    const refs = reactive({
      left: ['client', 'role', 'technologies', 'demo'],
      right: ['platform', 'work', 'technologies', 'demo']
    });

    return {
      props,
      refs
    };
  }
});
