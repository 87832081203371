
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'CopyLink',
  setup() {
    let refs = reactive({
      copied: false
    });

    function copy(event: PointerEvent) {
      if (!refs.copied) {
        const text = (event.target as HTMLElement).innerText;
        navigator.clipboard.writeText(text);
        refs.copied = true;
        setTimeout(() => refs.copied = false, 2000);
      }
    }

    return {
      copy,
      refs
    };
  }
});
