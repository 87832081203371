
import { defineComponent, reactive, ref, onMounted } from 'vue';
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: 'ExpansionPanel',
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const slot = ref<HTMLElement>();
    const refs = reactive({
      open: false,
      slotHeight: '0'
    });

    onMounted(() => {
      getSlotHeight();
      window.addEventListener('resize', () => getSlotHeight());
    });

    function getSlotHeight() {
      if (slot.value && slot.value.children[0]) {
        refs.slotHeight = `${slot.value.children[0].clientHeight}px`;
      }
    }

    function toggle() {
      refs.open = !refs.open;
    }

    function onKeyPress(event: KeyboardEvent) {
      if ((event.keyCode && event.keyCode === 13) || event.key === '13') {
        toggle();
      }
    }

    return {
      props,
      slot,
      refs,
      toggle,
      onKeyPress
    };
  }
});
