
import { defineComponent } from 'vue';
import Icon from '@/components/icons/Icon.vue';
import { NavButtons } from '@/components/global/HeaderButtons.vue';
import CopyLink from "@/components/CopyLink.vue";

export default defineComponent({
  name: 'Footer',
  components: {
    Icon,
    CopyLink
  },
  setup() {
    return {
      NavButtons
    };
  }
});
