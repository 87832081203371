
import { defineComponent, PropType } from 'vue';
import { DevelopmentEntry } from '@/data/development';
import ContentHeader from '@/components/ContentHeader.vue';
import EntryCarousel from '@/components/development/EntryCarousel.vue';
import EntryDetails from '@/components/development/EntryDetails.vue';

export default defineComponent({
  name: 'DevelopmentEntry',
  components: {
    ContentHeader,
    EntryCarousel,
    EntryDetails
  },
  props: {
    path: {
      type: String,
      default: undefined
    },
    entry: {
      type: Object as PropType<DevelopmentEntry>,
      required: true
    }
  },
  setup(props) {
    return {
      props
    };
  }
});
