
import { defineComponent } from 'vue';
import Banner from '@/components/Banner.vue';
import LiveText from '@/components/LiveText.vue';
import ContentHeader from '@/components/ContentHeader.vue';
import ContentCard from '@/components/ContentCard.vue';
import ExpansionPanel from '@/components/ExpansionPanel.vue';
import router from '@/router';
import Roles from '@/data/roles';
import Courses, { CourseType } from '@/data/courses';
import Skills from '@/data/skills';
import { DateTime } from 'luxon';

export default defineComponent({
  name: 'HomePage',
  components: {
    Banner,
    LiveText,
    ContentHeader,
    ContentCard,
    ExpansionPanel
  },
  setup() {
    const currentYear = DateTime.now().year;

    function goToPortfolio(n: number) {
      router.push({ name: n ? 'Design' : 'Development' });
    }

    function getCourses(courseType: CourseType) {
      return Courses.filter(c => c.type === courseType);
    }

    return {
      Roles,
      Skills,
      goToPortfolio,
      getCourses,
      CourseType,
      currentYear
    };
  }
});
