<template>
  <path
    d="M2.17,15.3V8.61c.05-.63.05-1.26.16-1.88a5.06,5.06,0,0,1,4-4.27,11.71,11.71,0,0,1,3-.27h5.82a14.21,14.21,0,0,1,2.25.17,5.05,5.05,0,0,1,4.25,4,11.55,11.55,0,0,1,.26,2.93v6.15a9.07,9.07,0,0,1-.25,2.32,5,5,0,0,1-2.93,3.55,7.38,7.38,0,0,1-2.79.55H8.6a10.12,10.12,0,0,1-2.27-.25,5,5,0,0,1-3.56-3,7.7,7.7,0,0,1-.52-2.73A3.19,3.19,0,0,1,2.17,15.3Zm18-3.25h0V9.58A13.6,13.6,0,0,0,20,7.26a3.5,3.5,0,0,0-.85-2,3.57,3.57,0,0,0-2.64-1.14C15.34,4,14.18,4,13,4S10.72,4,9.57,4a14,14,0,0,0-2.33.13,3.45,3.45,0,0,0-2,.84A3.54,3.54,0,0,0,4.06,7.57C4,8.73,4,9.9,4,11.07s0,2.29,0,3.44a12.91,12.91,0,0,0,.14,2.32,3.49,3.49,0,0,0,.84,2.05,3.61,3.61,0,0,0,2.61,1.19c1.17.07,2.34.08,3.5.1s2.3,0,3.44,0A18.11,18.11,0,0,0,17,20a3.21,3.21,0,0,0,2.8-2.48A7,7,0,0,0,20,16.08C20.1,14.7,20.11,13.37,20.13,12.05Z"
  /><path
    d="M12,17.12a5.1,5.1,0,1,1,.05,0Zm0-8.35a3.29,3.29,0,1,0,3.27,3.31h0A3.28,3.28,0,0,0,12,8.76h0Z"
  /><path d="M17.3,5.59A1.19,1.19,0,1,1,17.3,8h0a1.19,1.19,0,0,1,0-2.38Z" />
</template>

<script>
export default {
  name: 'Icon' + 'Instagram'
};
</script>
