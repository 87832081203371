
import { defineComponent, reactive, onMounted } from 'vue';
import Sleep from '@/util/Sleep';

const textOptions: string[] = [
  'See how I think',
  'See where I shine',
  'See my passion',
  'See my creativity',
  'See my ingenuity',
  'See what I do best'
];

export default defineComponent({
  name: 'LiveText',
  setup() {
    const refs = reactive({
      text: '',
      flash: false
    });
    let nextText = '';
    let remainingTextOptions: string[] = [];
    const baseDuration = 150;

    onMounted(() => {
      refs.text = getRandomText();
      pause(baseDuration * 10).then(changeText);
    });

    async function changeText() {
      nextText = getRandomText();
      // delete characters until prefixes match
      while (refs.text !== nextText.slice(0, refs.text.length)) {
        refs.text = refs.text.slice(0, refs.text.length - 1);
        await Sleep(baseDuration);
      }
      await Sleep(baseDuration * 4); // sleep between deleting and adding characters
      // add characters until text is fully updated
      while (refs.text !== nextText) {
        refs.text += nextText[refs.text.length];
        await Sleep(baseDuration);
      }
      pause(baseDuration * 20).then(changeText); // pause before changing text again
    }

    function getRandomText(): string {
      // refresh text options
      if (remainingTextOptions.length === 0) {
        remainingTextOptions = [...textOptions];
      }
      // get random text in remaining text options
      const index = Math.floor(Math.random() * remainingTextOptions.length);
      const newText = remainingTextOptions[index];
      remainingTextOptions.splice(index, 1);
      return newText;
    }

    async function pause(duration: number) {
      refs.flash = true;
      await Sleep(duration);
      refs.flash = false;
    }

    return {
      refs
    };
  }
});
