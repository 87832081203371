
import { defineComponent, PropType, onBeforeMount, reactive } from 'vue';
import { DesignEntry } from '@/data/design';
import RequireImage from '@/util/RequireImage';

export default defineComponent({
  name: 'DesignCard',
  props: {
    entry: {
      type: Object as PropType<DesignEntry>,
      required: true
    }
  },
  setup(props) {
    const refs = reactive({
      index: 0
    });
    const paths: string[] = [];

    onBeforeMount(() => {
      for (let i = 0; i < props.entry.contentCount; i++) {
        paths.push(RequireImage(`/design/${props.entry.key}/_${i}`));
      }
    });

    function goToObject(index: number) {
      refs.index = index;
    }

    function goToNextObject() {
      refs.index = refs.index === paths.length - 1 ? 0 : refs.index + 1;
    }

    return {
      props,
      refs,
      paths,
      goToObject,
      goToNextObject
    };
  }
});
