<template>
  <path
    class="cls-1"
    d="M12,2a10.21,10.21,0,0,0-3.23,19.9c.51.09.7-.22.7-.49s0-.89,0-1.74C6.62,20.33,6,18.34,6,18.34a2.7,2.7,0,0,0-1.14-1.49c-.92-.63.07-.62.07-.62a2.14,2.14,0,0,1,1.57,1.05,2.17,2.17,0,0,0,3,.85,2.15,2.15,0,0,1,.65-1.37c-2.27-.25-4.65-1.13-4.65-5A3.9,3.9,0,0,1,6.54,9a3.62,3.62,0,0,1,.1-2.7s.85-.28,2.8,1a9.81,9.81,0,0,1,5.12,0c2-1.32,2.8-1,2.8-1a3.62,3.62,0,0,1,.1,2.7,3.9,3.9,0,0,1,1.05,2.74c0,3.92-2.38,4.78-4.66,5a2.4,2.4,0,0,1,.69,1.89c0,1.36,0,2.46,0,2.8s.19.59.7.49A10.21,10.21,0,0,0,12,2Z"
  />
</template>

<script>
export default {
  name: 'Icon' + 'GitHub'
};
</script>
