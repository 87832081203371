<template>
  <path
    d="M12,22H3.48A1.47,1.47,0,0,1,2,20.79a1.5,1.5,0,0,1,0-.22V3.44A1.43,1.43,0,0,1,3.42,2H6.14c4.79,0,9.59,0,14.39,0A1.44,1.44,0,0,1,22,3a1.47,1.47,0,0,1,.06.42Q22,12,22,20.57A1.43,1.43,0,0,1,20.59,22H12Zm.69-11.24c0-.4,0-.74,0-1.09,0-.17,0-.22-.22-.22H10c-.2,0-.24.06-.24.24q0,4.57,0,9.14c0,.19.05.25.25.25q1.27,0,2.55,0c.19,0,.24-.06.24-.25,0-1.53,0-3.06,0-4.6a6,6,0,0,1,.07-.94A1.51,1.51,0,0,1,14,12a2.81,2.81,0,0,1,.65-.06,1.23,1.23,0,0,1,1.32,1,5.11,5.11,0,0,1,.12,1.17c0,1.58,0,3.15,0,4.73,0,.18.05.25.24.25q1.28,0,2.55,0c.19,0,.23-.06.23-.24V13.78a9,9,0,0,0-.19-2.07,3,3,0,0,0-1.26-2,3.24,3.24,0,0,0-1.47-.48,4,4,0,0,0-1.88.15A3.2,3.2,0,0,0,12.67,10.78ZM4.91,14.29v4.58c0,.18.06.23.23.23.86,0,1.72,0,2.57,0,.17,0,.23-.05.23-.22V9.68c0-.18-.06-.21-.23-.21H5.18c-.27,0-.27,0-.27.26Zm1.5-6.1A1.75,1.75,0,1,0,4.68,6.45,1.75,1.75,0,0,0,6.41,8.19Z"
  />
</template>

<script>
export default {
  name: 'Icon' + 'LinkedIn'
};
</script>
