
import { defineComponent, reactive } from 'vue';
import CoverService from '@/services/CoverService';

export default defineComponent({
  name: 'CoverController',
  setup() {
    const refs = reactive({
      visible: CoverService.getVisibleRef(),
      color: CoverService.getColorRef()
    });

    function onClick() {
      CoverService.removeCover();
    }

    return {
      refs,
      onClick
    };
  }
});
