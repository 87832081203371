
import { defineComponent } from 'vue';
import Pattern from '@/components/patterns/Pattern.vue';
// import Graphics from '@/components/graphics/Graphics.vue';
// import GraphicsMobile from '@/components/graphics/GraphicsMobile.vue';
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: 'Banner',
  components: {
    Pattern,
    // Graphics,
    // GraphicsMobile,
    Icon
  },
  props: {
    graphics: {
      type: String,
      required: true
    },
    reduced: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    function autoScroll() {
      window.scroll({ top: document.getElementById('scroll-mark')!.offsetTop, behavior: 'smooth' });
    }

    return {
      props,
      autoScroll
    };
  }
});
