<template>
  <path d="M13.1,15.4H11.68L9.62,10.81V15.4H8.21v-7H9.62L11.69,13V8.41H13.1Z" />
  <path
    d="M16.53,8.41h1.41v4.84a2.33,2.33,0,0,1-.57,1.63,1.89,1.89,0,0,1-1.46.61,2,2,0,0,1-1.5-.57,2.26,2.26,0,0,1-.53-1.59h1.41c0,.66.21,1,.61,1s.61-.38.62-1.14Z"
  />
  <path d="M21,12.7l-.55.7v2H19.05v-7h1.41v3.05l.44-.76,1.3-2.29h1.73l-2,3.07,2,3.92H22.29Z" />
  <path
    d="M1.05,11.42v.77h.48v.43H1.05v2a.49.49,0,0,0,.06.29.23.23,0,0,0,.21.1.78.78,0,0,0,.21,0v.44a1.13,1.13,0,0,1-.36.06.55.55,0,0,1-.47-.22,1.09,1.09,0,0,1-.17-.63v-2H0v-.43H.52v-.77Z"
  />
  <path
    d="M2.68,12.52a.92.92,0,0,1,.76-.39q.86,0,.87,1.14V15.4H3.79V13.29a.91.91,0,0,0-.13-.53.45.45,0,0,0-.38-.16.49.49,0,0,0-.35.13.84.84,0,0,0-.25.34V15.4H2.15V10.85h.53Z"
  />
  <path
    d="M6.23,15.46a1.18,1.18,0,0,1-.92-.36,1.55,1.55,0,0,1-.32-1v-.39a1.81,1.81,0,0,1,.31-1.13,1.08,1.08,0,0,1,.88-.41,1,1,0,0,1,.84.36,1.86,1.86,0,0,1,.28,1.12V14H5.51V14a1.17,1.17,0,0,0,.2.75.65.65,0,0,0,.55.25A.88.88,0,0,0,7,14.65l.27.33A1.17,1.17,0,0,1,6.23,15.46Zm-.05-2.87a.55.55,0,0,0-.49.22,1.4,1.4,0,0,0-.18.7H6.78v-.08a1.24,1.24,0,0,0-.17-.65A.49.49,0,0,0,6.18,12.59Z"
  />
</template>

<script>
export default {
  name: 'Icon' + 'NJK'
};
</script>
