
import { defineComponent } from 'vue';

export const NavButtons = [
  { label: 'Home', route: 'Home' },
  { label: 'Development', route: 'Development' },
  { label: 'Design', route: 'Design' }
];

export default defineComponent({
  name: 'HeaderButtons',
  emits: ['navigate'],
  setup(_props, ctx) {
    function emitNavigate() {
      ctx.emit('navigate');
    }

    return {
      NavButtons,
      emitNavigate
    };
  }
});
